import { createContext, useContext } from "react";
import { customToast } from "../../utils/toast";
import { servicesRequest } from "./services";

const RequestContext = createContext<any>(null);

export const RequestProvider = ({ children }: any) => {
  const getSolicitedRequest = async (setListRequest: any) => {
    try {
      const response = await servicesRequest.getRequest();
      setListRequest(response.data.response.data);
    } catch (err) {
      customToast("Erro ao carregar solicitações", "error");
    }
  };

  return (
    <RequestContext.Provider value={{ getSolicitedRequest }}>
      {children}
    </RequestContext.Provider>
  );
};

export const useRequest = () => useContext(RequestContext);

import { useCallback, useEffect, useRef, useState } from "react";
import { BsClockHistory } from "react-icons/bs";
import { Banner } from "../../components/Banner";
import { CardRequest } from "../../components/CardRequest";
import { IfLoaderInsideScreen } from "../../components/IfLoaderInsideScreen";
import { Layout } from "../../components/Layout";
import { useRequest } from "../../providers/Request";
import style from "./styles.module.scss";
export default function Solicitation() {
  const { getSolicitedRequest } = useRequest();

  const [loading, setLoading] = useState(false);

  const [listRequest, setListRequest] = useState([]);

  const firstLoad = useRef(true);

  const getSolicitation = useCallback(async () => {
    setLoading(true);
    await getSolicitedRequest(setListRequest);
    setLoading(false);
  }, [getSolicitedRequest]);

  useEffect(() => {
    if (firstLoad.current === true) {
      getSolicitation();
    }
    return () => {
      firstLoad.current = false;
    };
  }, [firstLoad, getSolicitation, getSolicitedRequest]);

  return (
    <Layout>
      <Banner text="Solicitações" icon={<BsClockHistory />} />
      <div className={style.container}>
        <IfLoaderInsideScreen loading={loading}>
          <CardRequest icon={<BsClockHistory />} list={listRequest} />
        </IfLoaderInsideScreen>
      </div>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
export const HourNow = () => {
  const [minute, setMinute] = useState(0);
  const [hour, setHour] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      setMinute(date.getMinutes());
      setHour(date.getHours());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className={style.hour}>
      <span>
        {(hour < 10 ? `0${hour}` : hour) +
          "h" +
          (minute < 10 ? `0${minute}` : minute) +
          "m"}
      </span>
    </div>
  );
};

import { Box } from "@mui/material";
import { useAuth } from "../../providers/Auth/Auth";
import { AvatarUser } from "../Avatar";
import { HourNow } from "../HourNow";
import { Layout } from "../Layout";
import style from "./styles.module.scss";
export const Header = () => {
  const { userData } = useAuth();
  return (
    <Layout>
      {userData != null ? (
        <Box component="header" className={style.profile}>
          <HourNow />
          <div
            className={style.container}
            style={{ borderBottom: "1px solid var(--border)" }}
          >
            <div className={style.containerImg}>
              <AvatarUser img={userData.image} />
            </div>
            <h3>{userData.name}</h3>
            <p>{userData.employee.company_office.name}</p>
          </div>
        </Box>
      ) : (
        ""
      )}
    </Layout>
  );
};

import Box from "@mui/material/Box";
import styleSass from "./styles.module.scss";
import { ListItem } from "../ListItem";
import { formatDateTime } from "../../utils/dateTime";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useState } from "react";
import { If } from "../If";
import { usePointChange } from "../../providers/PointChange";

export const ModalDetails = ({ open, handleClose, item, setOpen }: any) => {
  const { handleSendPointChange, handleReceipt } = usePointChange();
  const [activeChangePoint, setActiveChangePoint] = useState(false);
  const [pointChangeLoading, setPointChangeLoading] = useState(false);

  const [loadingSendReceipt, setLoadingSendReceipt] = useState(false);

  const [text, setText] = useState("");

  const handleChangePoint = useCallback(() => {
    setActiveChangePoint(true);
  }, []);
  const handleCloseActiveChangePoint = useCallback(() => {
    setActiveChangePoint(false);
  }, []);

  const handlePostNewPoint = async () => {
    setPointChangeLoading(true);
    await handleSendPointChange(item, text, setPointChangeLoading);
    setText("");
    setActiveChangePoint(false);
    setPointChangeLoading(false);
    setOpen(false);
  };

  const handleSendNewReceipt = async () => {
    setLoadingSendReceipt(true);
    await handleReceipt(item);
    setLoadingSendReceipt(false);
    setOpen(false);
  };

  return (
    <>
      <If condition={!activeChangePoint}>
        <div className={styleSass.container}>
          <Modal
            isOpen={open}
            toggle={handleClose}
            style={{ marginTop: "2rem" }}
          >
            <ModalHeader toggle={handleClose}>Detalhes do Ponto</ModalHeader>
            <ModalBody>
              <Box component="div" className={styleSass.container}>
                {item.selfie ? <img src={item.selfie} alt="selfie" /> : ""}
              </Box>
              <Box component="div" className={styleSass.containerContent}>
                <ListItem
                  title="Horário de registro"
                  description={
                    item.schedule !== undefined
                      ? formatDateTime(item.schedule)
                      : "loading..."
                  }
                />
                <ListItem
                  title="Tipo"
                  description={
                    item.type === "saida" || item.type === "entrada"
                      ? item.type.toUpperCase()
                      : "loading..."
                  }
                />

                <div style={{ fontSize: "1rem" }} className="content">
                  <ListItem title="Endereço" description={item.address} />
                  {item != null && item.point_change != null && (
                    <ListItem
                      title="Solicitação de alteração"
                      description={item.point_change.status}
                    />
                  )}
                  {item != null && item.point_change != null && (
                    <ListItem
                      title="Observação"
                      description={item.point_change.obs}
                    />
                  )}
                </div>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Box className={styleSass.buttons}>
                <If condition={item.point_change === null}>
                  <button onClick={handleChangePoint}>Alteração Ponto</button>
                </If>
                <button onClick={handleSendNewReceipt}>
                  Enviar Comprovante{" "}
                  {loadingSendReceipt && <Spinner size="sm" />}
                </button>
              </Box>
            </ModalFooter>
          </Modal>
        </div>
      </If>

      <If condition={activeChangePoint}>
        <div className={styleSass.container}>
          <Modal
            isOpen={open}
            toggle={handleCloseActiveChangePoint}
            style={{ marginTop: "2rem" }}
          >
            <ModalHeader toggle={handleCloseActiveChangePoint}>
              Solicitar alteração
            </ModalHeader>
            <ModalBody>
              <div className={styleSass.containerTextArea}>
                <textarea
                  placeholder="Digite sua observação"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Box className={styleSass.buttons}>
                <button onClick={() => handlePostNewPoint()}>
                  Enviar Solicitação{" "}
                  {pointChangeLoading && <Spinner size="sm" />}{" "}
                </button>
              </Box>
            </ModalFooter>
          </Modal>
        </div>
      </If>
    </>
  );
};

import { AiOutlineCloseSquare } from "react-icons/ai";
import { BiAnalyse } from "react-icons/bi";
import { BsCheckSquare, BsGeo } from "react-icons/bs";

export const switchCaseIcons = (type: string) => {
    switch (type) {
        case "Em Análise":
        return <BiAnalyse  style={{ marginRight: ".5rem", color: "var(--iconCheckedDisabled)", marginBottom: "2px" }} />;
        case "Aprovado":
        return <BsCheckSquare style={{ marginRight: ".5rem", color: "var(--iconChecked)", marginBottom: "2px" }} />;
        case "Recusado":
        return <AiOutlineCloseSquare style={{ marginRight: ".5rem", color: "var(--iconUnchecked)", marginBottom: "2px" }} />;
        default:
        return <BsGeo style={{ marginRight: ".5rem", color: "var(--iconCheckedDisabled)", marginBottom: "2px" }} />;
    }
    }
import React from 'react'
import style from "./styles.module.scss"
export const ListItem = ({ title, description }: any) => {
  return (
    <div className={style.container}>
        <div className={style.content}>
            <p>{title}: {description}</p>
        </div>
    </div>
  )
}

import api from "../../services/api/api";

export const captureService = {
    sendPhoto: async (photo: any) => {
        return await api.post(`/user/updatePicture`, photo, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
}
import { createContext, useCallback, useContext, useState } from "react";
import { user } from "../Auth/services";

const UserContext = createContext<any>(null);

export const UserProvider = ({ children }: any) => {
  const [shift, setShift] = useState([]);
  const [loader, setLoading] = useState(true);

  const getShift = useCallback((user_id: any, set: any) => {
    setLoading(true);
    user
      .getShifts(user_id)
      .then((res) => {
        set(res.data.response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const getMe = useCallback(() => {
    user
      .me()
      .then((res) => {
        return res.data.response;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <UserContext.Provider value={{ shift, setShift, getShift, getMe, loader }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

import style from "./styles.module.scss";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useState } from "react";
import { usePassword } from "../../providers/Password";
import { customToast } from "../../utils/toast";
import { If } from "../If";
import "./reactInput.css";
import ReactInputVerificationCode from "react-input-verification-code";

export const ModalSendCode = ({ open, handleClose }: any) => {
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { verifyCode } = usePassword();

  const handleCode = useCallback(
    async (e: any) => {
      e.preventDefault();

      if (code === "" || code === "······") {
        return customToast("Preencha o código de verificação", "error");
      }

      setLoading(true);
      await verifyCode(code, handleClose);

      setLoading(false);
    },
    [code, handleClose, verifyCode]
  );

  return (
    <>
      <If condition={open}>
        <div className={style.container}>
          <Modal isOpen={open} toggle={handleClose} centered>
            <ModalHeader toggle={handleClose}>Enviar código </ModalHeader>
            <form onSubmit={handleCode}>
              <ModalBody>
                <div className={style.formGroup}>
                  {/* <Input
                    name="code"
                    onChange={(e) => setCode(e.target.value)}
                  /> */}
                  <div className="custom-styles">
                    <ReactInputVerificationCode
                      onChange={(e) => setCode(e)}
                      length={6}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="outlined"
                  className={style.btn}
                  type="submit"
                  disabled={loading}
                >
                  Enviar Código {loading && <Spinner size="sm" />}
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
      </If>
    </>
  );
};

import React from "react";
import { AuthProvider } from "./Auth/Auth";
import { CaptureProvider } from "./CaptureEdit";
import { HistoryProvider } from "./History";
import { PasswordProvider } from "./Password";
import { PointChangeProvider } from "./PointChange";
import { RequestProvider } from "./Request";
import { UserProvider } from "./User";
import { ApprovalProvider } from "./Approval";

export const Providers = ({ children }: any) => {
  return (
    <AuthProvider>
      <UserProvider>
        <HistoryProvider>
          <PointChangeProvider>
            <PasswordProvider>
              <RequestProvider>
                <ApprovalProvider>
                  <CaptureProvider>{children}</CaptureProvider>
                </ApprovalProvider>
              </RequestProvider>
            </PasswordProvider>
          </PointChangeProvider>
        </HistoryProvider>
      </UserProvider>
    </AuthProvider>
  );
};

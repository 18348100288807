import { Card } from "../../components/Card";
import { useAuth } from "../../providers/Auth/Auth";
import { SpinLoading } from "../../components/SpinLoading";
import { ButtonCamera } from "../../components/ButtonCamera";
import { HeaderHome } from "../../components/HeaderHome";
import style from "./styles.module.scss";
import { Layout } from "../../components/Layout";
import { HourNow } from "../../components/HourNow";
export default function Home() {
  const { loading } = useAuth();

  return (
    <>
      {loading && <SpinLoading />}
      <HourNow />
      <Layout>
        <div className={style.header}>
          <HeaderHome />
        </div>
        <Card />
        <ButtonCamera text={"Registrar Ponto"} component="" />
      </Layout>
    </>
  );
}

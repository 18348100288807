import { Avatar, Badge } from "@mui/material";
import React from "react";
import { HiPencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import style from "./styles.module.scss";

export const AvatarUser = ({ img }: any) => {
  const navigate = useNavigate();

  const handleRouterEdit = () => {
    navigate("/editphoto");
  };

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className={style.avatar}
        badgeContent={<HiPencil onClick={handleRouterEdit} />}
      >
        <Avatar
          alt="selfie"
          src={img}
          sx={{ width: "var(--avatarSize)", height: "var(--avatarSize)" }}
        />
      </Badge>
    </>
  );
};

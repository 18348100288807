import { createContext, useContext, useState } from "react";
import { customToast } from "../../utils/toast";
import { useHistory } from "../History";
import { pointChangeService } from "./service";

const PointChangeContext = createContext<any>(null);

export const PointChangeProvider = ({ children }: any) => {
  const { getHistory } = useHistory();
  const [pointChangeLoading, setPointChangeLoading] = useState(false);
  const [loaderHistory, setLoaderHistory] = useState(false);

  const handleSendPointChange = async (item: any, text: any) => {
    setPointChangeLoading(true);
    if (item !== undefined) {
      try {
        const response = await pointChangeService.postPointChange(item, text);
        setPointChangeLoading(false);
        customToast("Solicitação Feita!", "success");
        getHistory(setLoaderHistory);
        return response;
      } catch {
        setPointChangeLoading(false);
      }
    }
  };

  // async function handleReceipt() {
  //   try {
  //     setReceiptLoading(true);
  //     await api.post(`pointRegister/${item.id}/receipt`);
  //     setReceiptLoading(false);

  //     message.success("Seu comprovante foi enviado por e-mail");
  //   } catch (error) {

  //     message.info("Ocorreu um erro ao enviar seu comprovante");
  //   }
  // }
  const handleReceipt = async (item: any) => {
    try {
      const response = await pointChangeService.postSendReceipt(item);
      customToast("Seu comprovante foi enviado por e-mail", "success");
      return response;
    } catch (error) {
      customToast("Ocorreu um erro ao enviar seu comprovante", "error");
    }
  };
  return (
    <PointChangeContext.Provider
      value={{
        handleSendPointChange,
        pointChangeLoading,
        handleReceipt,
        loaderHistory,
      }}
    >
      {children}
    </PointChangeContext.Provider>
  );
};

export const usePointChange = () => {
  return useContext(PointChangeContext);
};

import style from "./styles.module.scss";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { useApproval } from "../../providers/Approval";

interface ModalItemApprovalProps {
  open: boolean;
  handleClose: () => void;
  approval: {
    id: number;
    status: "approved" | "refused";
  };
  updateList: () => Promise<void>;
}

export const ModalItemApproval = ({
  open,
  handleClose,
  approval,
  updateList,
}: ModalItemApprovalProps) => {
  const [loading, setLoading] = useState(false);

  const { updateStatusApproval } = useApproval();

  const approveOrRefuse = async () => {
    setLoading(true);
    await updateStatusApproval(approval.id, approval.status);
    await updateList();
    setLoading(false);

    handleClose();
  };

  return (
    <>
      <div className={style.container}>
        <Modal isOpen={open} toggle={handleClose} centered>
          <ModalHeader toggle={handleClose}>Confirmar ação</ModalHeader>
          <ModalBody>
            Tem certeza que deseja{" "}
            {approval.status === "approved" ? "aprovar" : "recusar"} essa
            solicitação?
          </ModalBody>
          <ModalFooter>
            <Button
              variant="outlined"
              className={style.btn}
              onClick={() => approveOrRefuse()}
            >
              Confirmar {loading && <Spinner size="sm" />}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

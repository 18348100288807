import { Button } from "reactstrap"
import style from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
export const ButtonCamera = ({component, text}: any) => {
  const navigate = useNavigate();
  const routerCamera = () => {
    if (text) {
      return navigate("/capture");
    } else {
      return
    }
  }
  return (
    <div className={style.cam}>
      <Button
      onClick={() => routerCamera()}
      >
        <span>
          {" "}
           {/* <img src={selfie} alt="selfie" /> */}
           {component} { text ?? "" } 
        </span>
      </Button>
    </div>
  );
};

import { Box } from '@mui/material'
import React from 'react'
import style from "./styles.module.scss"


export const BoxContainerLayout = ({ children }: any) => {
  return (
    <Box className={style.containerLayout} >
        {children}
    </Box>
  )
}

import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";

import { FiHome } from "react-icons/fi";
import { AiOutlineHistory } from "react-icons/ai";
import style from "./styles.module.scss";
import { NavigationLinkBottom } from "../NavigationLinkBottom";
import { MdMenu } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";

export default function LinkSideBar() {
  const [value, setValue] = React.useState("home");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className={style.sidebar}>
      <BottomNavigation
        sx={{
          width: "100%",
          height: "5rem",
          backgroundColor: "var(--background)",
        }}
        value={value}
        onChange={handleChange}
      >
        <NavigationLinkBottom
          to="/home"
          value="home"
          iconComponent={
            <FiHome
              style={{ color: "var(--white)" }}
              className="history"
              size={25}
            />
          }
        />
        <NavigationLinkBottom
          to="/history"
          value="history"
          iconComponent={
            <AiOutlineHistory
              style={{ color: "var(--white)" }}
              className="history"
              size={25}
            />
          }
        />
        <NavigationLinkBottom
          to="/notify"
          value="notify"
          iconComponent={
            <IoMdNotificationsOutline
              style={{ color: "var(--white)" }}
              className="menu"
              size={25}
            />
          }
        />
        <NavigationLinkBottom
          to="/settings"
          value="settings"
          iconComponent={
            <MdMenu
              style={{ color: "var(--white)" }}
              className="menu"
              size={25}
            />
          }
        />

        {/* <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleLogout}
        >
          <BottomNavigationAction
            value="leave"
            icon={
              <AiOutlineLogout
                style={{ color: "#fff" }}
                className="leave"
                size={25}
              />
            }
          />
        </div> */}
      </BottomNavigation>
    </div>
  );
}

import style from "./styles.module.scss";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useState } from "react";
import Input from "../Input";
import { usePassword } from "../../providers/Password";

export const ModalChangePasswordLogin = ({
  open,
  handleClose,
  setOpen,
  setOpenModalCode,
}: any) => {
  const { sendEmailLogin } = usePassword();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState<string>("");

  const handleSendEmail = useCallback(
    async (e: any) => {
      e.preventDefault();
      setLoading(true);
      await sendEmailLogin(email, setOpenModalCode);
      handleClose();
      setLoading(false);
    },
    [email, handleClose, sendEmailLogin, setOpenModalCode]
  );

  return (
    <>
      <div className={style.container}>
        <Modal isOpen={open} toggle={handleClose} centered>
          <ModalHeader toggle={handleClose}>
            Enviar código para email{" "}
          </ModalHeader>
          <form>
            <ModalBody>
              <label>Digite seu Email: </label>
              <div className={style.formGroup}>
                <Input
                  name="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outlined"
                className={style.btn}
                onClick={handleSendEmail}
                type="submit"
                disabled={loading}
              >
                Enviar código {loading && <Spinner size="sm" />}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </>
  );
};

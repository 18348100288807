import { Layout } from "../../components/Layout";
import { BoxContainerLayout } from "../../components/BoxContainerLayout";
import { Banner } from "../../components/Banner";
import { IoIosNotifications } from "react-icons/io";
import style from "./styles.module.scss";

export default function Notify() {
  return (
    <>
      <Layout>
        <Banner text={"Notificações"} icon={<IoIosNotifications />} />
        <BoxContainerLayout>
          <div className={style.notify}>
            <p> Sem Notificações </p>
          </div>
        </BoxContainerLayout>
      </Layout>
    </>
  );
}

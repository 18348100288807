import api from "../../services/api/api";

export const userApi = {
    postPointRegister: async(user:any, data:any): Promise<any> => {
        return api.post(`/company/${user.employee.company_id}/pointRegister/mark`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
    },
}
import React from "react";
import style from "./styles.module.scss";
import { formatDateTime } from "../../utils/dateTime";
import { BsCheckSquare, BsGeo, BsSearch } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { MdApproval } from "react-icons/md";
import { ModalDetails } from "../Modal";
import { If } from "../If";

interface CardHistoryProps {
  type?: string;
  icon?: React.ReactElement;
  list?: any;
}

export const CardHistory = ({ type, icon, list }: CardHistoryProps) => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState(null);
  const handleOpen = (item: any) => {
    setOpen(true);
    setItem(item);
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <div className={style.card}>
        <div className={style.containerTitle}>
          <div className={style.details} onClick={() => handleOpen(list)}>
            <BsSearch size={20} color="var(--background)" />
          </div>
          <h3>
            {" "}
            {list.type}{" "}
            <span>
              <BsCheckSquare
                color={list.type === "saida" ? "#D10000" : "green"}
              />
            </span>{" "}
          </h3>
        </div>
        <div className={style.containerContent}>
          <div className={style.content}>
            {list != null && list.point_change != null && (
              <div className={style.address}>
                <span>
                  <MdApproval
                    style={{ marginRight: ".5rem", marginBottom: ".5rem" }}
                  />
                </span>
                <p> {list.point_change.status} </p>
              </div>
            )}
            <p>
              {" "}
              <BiTime style={{ marginRight: ".5rem" }} />{" "}
              {formatDateTime(list.schedule)}{" "}
            </p>
            <div className={style.address}>
              <span>
                <BsGeo style={{ marginRight: ".5rem" }} />
              </span>
              <p> {list.address || "Não informado"} </p>
            </div>
          </div>
        </div>
      </div>
      <If condition={item != null}>
        <ModalDetails
          open={open}
          item={item}
          handleClose={handleClose}
          setOpen={setOpen}
        />
      </If>
    </>
  );
};

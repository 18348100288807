import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../components/RequireAuth";
import { useAuth } from "../providers/Auth/Auth";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Capture from "../pages/Capture";
import History from "../pages/History";
import Settings from "../pages/Settings";
import Solicitation from "../pages/Solicitation";
import ForgotPassword from "../pages/ForgotPassword";
import Notify from "../pages/Notify";
import CaptureEdit from "../pages/CaptureEdit";
import Approvals from "../pages/Approvals";

export const Router = () => {
  const { signed } = useAuth();
  return (
    <>
      {signed ? (
        <Routes>
          <Route
            path="/capture"
            element={
              <RequireAuth>
                <Capture />
              </RequireAuth>
            }
          />
          <Route
            path="/history"
            element={
              <RequireAuth>
                <History />
              </RequireAuth>
            }
          />
          <Route
            path="/solicitation"
            element={
              <RequireAuth>
                <Solicitation />
              </RequireAuth>
            }
          />
          <Route
            path="/approval"
            element={
              <RequireAuth>
                <Approvals />
              </RequireAuth>
            }
          />
          <Route
            path="/home"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="/notify"
            element={
              <RequireAuth>
                <Notify />
              </RequireAuth>
            }
          />
          <Route
            path="/editphoto"
            element={
              <RequireAuth>
                <CaptureEdit />
              </RequireAuth>
            }
          />
          <Route
            path="*"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgotpassword/:id" element={<ForgotPassword />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </>
  );
};

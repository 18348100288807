import { FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "../../styles/Login.module.scss";
import logo from "../../assets/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
import { useAuth } from "../../providers/Auth/Auth";
import { cpfMask } from "../../utils/cpfMask";
import { AiOutlineUser } from "react-icons/ai";
import { If } from "../../components/If";
import { ModalChangePasswordLogin } from "../../components/ModalChangePasswordLogin";
import { ModalSendCode } from "../../components/ModalChangePasswordLogin/ModalSendCode";

export default function Login() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [revealPassword, setRevealPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [openModalKey, setOpenModalKey] = useState(false);
  const [openModalCode, setOpenModalCode] = useState(false);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    await signIn(cpf, password);
    navigate("/home");
    setLoading(false);
  };

  function RevealPassword(event: any) {
    event.preventDefault();
    setRevealPassword(!revealPassword);
  }

  const handleOpenModal = () => {
    setOpenModalKey(true);
  };
  const handleCloseModal = () => {
    setOpenModalKey(false);
  };

  const handleCloseModalCode = () => {
    setOpenModalCode(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={logo} alt="logo-login" />

          <form onSubmit={handleLogin}>
            <Input
              name="password"
              icon={AiOutlineUser}
              placeholder="Insira seu CPF"
              maxLength={14}
              value={cpf}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
            />
            <Input
              name="password"
              icon={FiLock}
              type={revealPassword ? "text" : "password"}
              placeholder="Insira sua senha"
              iconEye={revealPassword ? FiEyeOff : FiEye}
              showPassword={RevealPassword}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className={styles.forgotpassword}>
              <p onClick={handleOpenModal}>Esqueceu a senha? </p>
            </div>

            <Button type="submit" disabled={loading}>
              {loading ? <> {<Spinner size="sm" />} </> : "Login"}{" "}
            </Button>
          </form>
        </div>

        <div className={styles.background}></div>
      </div>

      <If condition={openModalKey}>
        <ModalChangePasswordLogin
          open={openModalKey}
          setOpen={setOpenModalKey}
          handleClose={handleCloseModal}
          setOpenModalCode={setOpenModalCode}
        />
      </If>

      <If condition={openModalCode}>
        <ModalSendCode
          open={openModalCode}
          handleClose={handleCloseModalCode}
        />
      </If>
    </>
  );
}

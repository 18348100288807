import api from "../../services/api/api";


export const user = {
  me : async () => {
    return await api.get("/auth/me");
  },
  login: async (cpf: string, password: string) => {
    return await api.post("/auth/login", {
      document: cpf.replace(/\D/g, ""),
      password
    });
  },
  getShifts: async (user_id: string) => {
   return await api.get(`/company/${user_id}/shifts`)
  },
  history: async (user: any) => {
    return await api.get(
      `company/${user.employee.company_id}/pointRegister/historic?`);
  }

};

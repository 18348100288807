import { Box } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { AiFillCamera } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { ArrowBackPage } from "../../components/ArrowBackPage";
import { Layout } from "../../components/Layout";
import style from "../Capture/styles.module.scss";

import { SiVerizon } from "react-icons/si";
import { ButtonCaptureCamera } from "../../components/ButtonCamera/ButtonCapture";
import { customToast } from "../../utils/toast";
import { IfLoaderInsideScreen } from "../../components/IfLoaderInsideScreen";
import { captureService } from "../../providers/CaptureEdit/service";

export default function CaptureEdit() {
  const navigate = useNavigate();
  const webcamRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const backPage = () => {
    navigate("/settings");
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  const handleClose = useCallback(() => {
    setImage(null);
  }, [setImage]);

  const handleSend = useCallback(
    (isTryAgain = false) => {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        function (position: any) {
          const file = dataURLtoFile(image, "image.jpg");

          const formData = new FormData();

          formData.append("photo", file);

          captureService
            .sendPhoto(formData)
            .then((response) => {
              console.log(response.status);
              customToast("Sua Foto foi Atualizada!", "success");
              navigate("/settings");
              window.location.reload();
            })
            .catch(() => {
              if (isTryAgain) {
                setLoading(false);
                navigate("/settings");
                customToast("Erro ao atualizar foto", "error");
              } else {
                setTimeout(() => {
                  handleSend(true);
                }, 1000);
              }
            });
        },
        function () {
          customToast("Erro ao atualizar foto", "error");
          setLoading(false);
          navigate("/settings");
        }
      );
    },
    [image, navigate]
  );

  return (
    <Layout>
      <div className={style.containerAll}>
        {!loading && (
          <div className={style.container} onClick={backPage}>
            <ArrowBackPage />
          </div>
        )}

        <IfLoaderInsideScreen loading={loading}>
          {!image && (
            <Webcam
              videoConstraints={{ facingMode: "user" }}
              className={style.camera}
              audio={false}
              ref={webcamRef}
              mirrored={true}
              screenshotFormat="image/jpeg"
            />
          )}
          {image && <img src={image} alt="selfie" />}
          {!image && (
            <ButtonCaptureCamera
              onClick={capture}
              component={<AiFillCamera size={40} />}
            />
          )}
          {image && !loading && (
            <Box className={style.buttons}>
              <button onClick={() => handleSend()} disabled={loading}>
                <SiVerizon size={20} />
              </button>
              <button onClick={handleClose} disabled={loading}>
                {" "}
                <IoMdClose fontSize={30} />{" "}
              </button>
            </Box>
          )}
        </IfLoaderInsideScreen>
      </div>
    </Layout>
  );
}
export function dataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

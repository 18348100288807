import { Box } from "@mui/material";
import React from "react";
import LinkSideBar from "../LinkSidebar";
import style from "./styles.module.scss";


export const Layout = ({ children }: any) => {
  return (
    <Box>
      <div className={style.containerLayout} >{children}</div>
      <LinkSideBar />
    </Box>
  );
};

import api from "../../services/api/api";

export const passwordService = {
  async putPassword(currentPassword: any, newPassword: any) {
    return await api.put(`user/password`, {
      password: currentPassword,
      new_password: newPassword,
    });
  },
};

export const passwordChangeLogin = {
  sendEmail: async (email: string) => {
    return await api.post("user/resetPassword", {
      email: email,
    });
  },
  postCode: async (code: string, token: string) => {
    return await api.post(
      "user/checkRecoveryCode",
      {
        code: code,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  resetPassword: async (password: string, token: string) => {
    return await api.put(
      "user/updateNewPassword",
      {
        new_password: password,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
};

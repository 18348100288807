import React, { ButtonHTMLAttributes } from "react";
import style from "./styles.module.scss";

type DivProps = ButtonHTMLAttributes<HTMLDivElement>;

export const CardSetting: React.FC<DivProps> = ({ children, ...rest }: any) => {
  return (
    <div className={style.card} {...rest}>
      <div className={style.cardTitle}>{children}</div>
    </div>
  );
};

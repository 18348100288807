import { createContext, useContext, useEffect, useState } from "react";
import api from "../../services/api/api";
import { customToast } from "../../utils/toast";
import { user } from "./services";

const AuthContext = createContext<any>(null);

export const AuthProvider = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<any>(null);
  const [userData, setUserData] = useState<any>(null);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const loadingStorageData = async () => {
    const storageToken = localStorage.getItem("token");
    if (storageToken) {
      api.defaults.headers.common["Authorization"] = `Bearer ${storageToken}`;
      setIsAuthenticated(storageToken);
      setFirstLoad(true);
      getDataUser();
    }
    configApiInterceptor();
  };

  useEffect(() => {
    loadingStorageData();
    // eslint-disable-next-line
  }, []);

  // async function refreshToken() {
  //   const storageUser = localStorage.getItem("@Auth:user");

  //   if (storageUser === null) {
  //     logout(isAuthenticated);
  //     return;
  //   }

  //   const userData = JSON.parse(storageUser);

  //   return api
  //     .put(`auth/refresh`, {
  //       company_id: userData.employee.company_id,
  //       user_id: userData.id,
  //     })
  //     .then(({ data }) => {
  //       saveAuthToken(data);
  //       return Promise.resolve(data);
  //     })
  //     .catch(() => {
  //       logout(isAuthenticated);
  //       return Promise.reject();
  //     });
  // }

  function configApiInterceptor() {
    api.interceptors.response.use(
      (resp) => resp,
      async (err) => {
        const error = err.response;
        if (error.status === 401) {
          logout(isAuthenticated);
          return;
          // @TODO: Reinplementar refresh token
          // if (error.config.url === "auth/refresh") {
          //   logout(isAuthenticated);
          //   return;
          // } else {
          //   const response = await refreshToken();
          //   return response;
          // }
        }
        throw err;
      }
    );
  }

  // async function saveAuthToken(data: any) {
  //   localStorage.setItem("token", JSON.stringify(data));
  //   api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
  //   setTimeout(() => {
  //     configApiInterceptor();
  //   }, 2000);
  // }

  const signIn = async (cpf: any, password: any) => {
    try {
      const response = await user.login(cpf, password);
      const { token } = await response.data;
      await localStorage.setItem("token", token);
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await getDataUser();
      setIsAuthenticated(response.data);
    } catch (err: any) {
      customToast(err.response.data.message, "error");
    }
  };

  const getShift = (user_id: any, set: any) => {
    user
      .getShifts(user_id)
      .then((res) => {
        setLoading(false);
        set(res.data.response);
      })
      .catch((err: any) => {
        setLoading(false);
        customToast(err.response.data.message, "error");
      });
  };

  const getDataUser = async () => {
    try {
      const response = await user.me();
      localStorage.setItem(
        "@Auth:user",
        JSON.stringify(response.data.response)
      );
      setUserData(response.data.response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   if (firstLoad === true) {
  //     getDataUser();
  //     setLoading(true)
  //   }
  // }, [firstLoad]);

  const logout = (isAuthenticated: any) => {
    setIsAuthenticated(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signed: !!isAuthenticated,
        signIn,
        logout,
        getDataUser,
        loading,
        firstLoad,
        getShift,
        userData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

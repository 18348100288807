import { createContext } from "react";



const CaptureContext = createContext<any>(null)

export const CaptureProvider = ({ children }: any) => {

    return (
        <CaptureContext.Provider value={{}}>
            {children}
        </CaptureContext.Provider>
    )
}

import api from "../../services/api/api";

export const servicesApproval = {
  getApprovals: async () => {
    return await api.get("/pointAdjustments");
  },

  updateStatusApproval: async (id: number, status: string) => {
    return await api.put(`/pointAdjustments/${id}/response`, {
      response_status: status,
    });
  },
};

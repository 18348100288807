import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from './routes/routes';
import "./styles/global.scss"
function App() {
  return (
    <>
      <Router />
      <ToastContainer />
    </>
  );
}

export default App;

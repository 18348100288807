import React from "react";
import { BsDoorClosed, BsDoorOpen } from "react-icons/bs";
import style from "./styles.module.scss";
export const CardHome = ({ item }: any) => {
  const formatHour = (hour: string) => {
    const response = hour.split(":");
    return response[0] + ":" + response[1];
  };
  return (
    <>
      <div className={style.card}>
        <div className={style.containerContent}>
          <div className={style.content}>
            <div className={style.subtitle}>
              <span>Entrada</span> <BsDoorOpen color="green" />
              <span>
                {" "}
                <br />
                {formatHour(item?.hour_start ?? "Não informado") + "h"}
              </span>
            </div>
            <div className={style.subtitle}>
              <span>Saída</span> <BsDoorClosed color="var(--red)" />
              <span>
                {" "}
                <br />
                {formatHour(item?.hour_end ?? "Não informado") + "h"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { CardSetting } from "../../components/CardSetting";
import { Header } from "../../components/Header";
import style from "./styles.module.scss";
import { BsClockHistory } from "react-icons/bs";
import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { IoIosLogIn } from "react-icons/io";
import { Button } from "@mui/material";
import { useState } from "react";
import { If } from "../../components/If";
import { ModalChangePassword } from "../../components/ModalChangePassword";
import { useAuth } from "../../providers/Auth/Auth";
import { FiLock, FiCheck } from "react-icons/fi";
export default function Settings() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <Layout>
        <Header />
        <section className={style.history}>
          <div className={style.cards}>
            <CardSetting onClick={() => navigate("/solicitation")}>
              <BsClockHistory />
              Solicitações
            </CardSetting>

            <CardSetting onClick={handleOpen}>
              <FiLock />
              Alterar Senha
            </CardSetting>

            <CardSetting onClick={() => navigate("/approval")}>
              <FiCheck />
              Aprovações
            </CardSetting>
          </div>

          <div className={style.containerLeave}>
            <Button variant="outlined" onClick={handleLogout}>
              Sair <IoIosLogIn />{" "}
            </Button>
          </div>
        </section>
      </Layout>

      <If condition={isOpen}>
        <ModalChangePassword
          open={isOpen}
          handleClose={handleClose}
          setOpen={setIsOpen}
        />
      </If>
    </>
  );
}

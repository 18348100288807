import api from "../../services/api/api";

export const pointChangeService = {
  async postPointChange(item: any, text: any) {
    return await api.post(`pointChange`, {
      point_register_id: item.id,
      employee_id: item.employee_id,
      company_id: item.company_id,
      obs: text,
    });
  },
  async postSendReceipt(item: any) {
    return await api.post(`pointRegister/${item.id}/receipt`)
  }
};

import { Box } from "@mui/material";
import { useAuth } from "../../providers/Auth/Auth";
import style from "./styles.module.scss";
export const HeaderHome = () => {
  const { userData } = useAuth();
  return (
    <>
      {userData != null ? (
        <Box component="header" className={style.profile}>
          <div
            className={style.container}
            // style={{ borderBottom: "1px solid #ccc" }}
          >
            <div className={style.profileInfo}>
              <span>Bem vindo,</span>
              <h5> {userData.name} </h5>
              {/* <p>
                Seu saldo é de 4 horas.
              </p> */}
            </div>
            {/* <div className={style.hours}>
              <p>
                Saldo de Horas
              </p>
              <p>
                15h
              </p>
            </div> */}
          </div>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

import React from "react";
import style from "./styles.module.scss";
import { formatDate, formatTime } from "../../utils/dateTime";
import { BsGeo } from "react-icons/bs";
import { BiCheck, BiSubdirectoryRight, BiTime, BiX } from "react-icons/bi";
import ApprovalConst from "../../services/api/constants/Approval";
import { Box } from "@mui/material";
import { If } from "../If";
import { ModalItemApproval } from "../ModalItemApproval";

interface CardApprovalProps {
  list: [];
  updateList: () => Promise<void>;
}

interface ApprovalToUpdate {
  id: number;
  status: "approved" | "refused";
}

export const CardItemApproval = ({ list, updateList }: CardApprovalProps) => {
  const [open, setOpen] = React.useState(false);
  const [approval, setApproval] = React.useState<ApprovalToUpdate>({
    id: 0,
    status: "approved",
  });

  const getApprovalTypeText = (typeId: number) => {
    switch (typeId) {
      case ApprovalConst.NEW_REGISTER:
        return "Solicitação de novo registro de ponto";
      case ApprovalConst.EDIT_REGISTER:
        return "Solicitação de alteração de registro de ponto";
      case ApprovalConst.DELETE_REGISTER:
        return "Solicitação de exclusão de registro de ponto";
      default:
        return "Solicitação de ajuste de registro de ponto";
    }
  };

  const getApprovalStatusClassName = (statusId: number) => {
    switch (statusId) {
      case ApprovalConst.STATUS_APPROVED:
        return style.approved;
      case ApprovalConst.STATUS_REJECTED:
        return style.rejected;
      default:
        return style.pending;
    }
  };

  const handleOpen = (item: ApprovalToUpdate) => {
    setOpen(true);
    setApproval(item);
  };

  const handleClose = () => {
    setOpen(false);
    setApproval({ id: 0, status: "approved" });
  };

  return (
    <>
      {list.length > 0 &&
        list.map((item: any) => {
          return (
            <>
              <div className={style.card} key={item.uuid}>
                <div className={style.containerTitle}>
                  <div className={style.details}>
                    <span className={style.title}>Status: {""}</span>
                    <span
                      className={getApprovalStatusClassName(
                        item.point_adjustment_status_id
                      )}
                    >
                      {item.point_adjustment_status.name}
                    </span>
                  </div>
                  <span>Data: {formatDate(item.schedule)}</span>
                </div>
                <div className={style.containerContent}>
                  <div className={style.content}>
                    <div className={style.titleKindOfApproval}>
                      <p>
                        {getApprovalTypeText(item.point_adjustment_type_id)}
                      </p>
                    </div>
                    <div className={style.address}>
                      <span>
                        <BsGeo
                          style={{
                            marginRight: ".5rem",
                            marginBottom: ".5rem",
                          }}
                        />
                      </span>
                      <p className={style.pointTitle}>
                        {" "}
                        Horário para aprovação:{" "}
                      </p>
                    </div>
                    <p>
                      {" "}
                      <span className={style.description}>
                        <BiTime style={{ marginRight: ".5rem" }} />{" "}
                        {formatTime(item.schedule)}
                      </span>
                    </p>
                    <div className={style.address}>
                      <span>
                        <BiSubdirectoryRight
                          style={{
                            marginRight: ".5rem",
                            marginBottom: ".5rem",
                          }}
                        />
                      </span>
                      <p className={style.pointTitle}> Tipo de registro: </p>
                    </div>
                    <p>
                      {" "}
                      <span className={style.description}>
                        <BiTime style={{ marginRight: ".5rem" }} /> {item.type}
                      </span>
                    </p>
                    <If
                      condition={
                        item.point_adjustment_status_id ===
                        ApprovalConst.STATUS_PENDING
                      }
                    >
                      <Box className={style.buttons}>
                        <button
                          onClick={() =>
                            handleOpen({ id: item.id, status: "approved" })
                          }
                        >
                          Aprovar <BiCheck style={{ fontSize: "1.1rem" }} />
                        </button>
                        <button
                          onClick={() =>
                            handleOpen({ id: item.id, status: "refused" })
                          }
                        >
                          Recusar <BiX style={{ fontSize: "1.1rem" }} />
                        </button>
                      </Box>
                    </If>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      <If condition={approval.id !== 0}>
        <ModalItemApproval
          open={open}
          handleClose={handleClose}
          updateList={updateList}
          approval={approval}
        />
      </If>
    </>
  );
};

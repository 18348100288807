export const ApprovalConst = {
  NEW_REGISTER: 1,
  EDIT_REGISTER: 2,
  DELETE_REGISTER: 3,

  STATUS_PENDING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3,
};

export default ApprovalConst;

import { BottomNavigationAction } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import style from "../LinkSidebar/styles.module.scss";

interface NavigationLink {
  value: string;
  iconComponent: any;
  to: string;
}

export const NavigationLinkBottom = ({
  to,
  value,
  iconComponent,
}: NavigationLink) => {
  return (
    <NavLink
      style={{ display: "flex", alignItems: "center" }}
      to={to}
      className={({ isActive }) =>
        isActive ? style["active-link"]  : style.link
      }
    >
      <BottomNavigationAction value={value} icon={iconComponent} />
    </NavLink>
  );
};

import { Button } from "reactstrap";
import style from "./styles.module.scss";
export const ButtonCaptureCamera = ({ component, text, onClick }: any) => {
  return (
    <div className={style.cam}>
      <Button onClick={onClick}>
        <span>
          {" "}
          {/* <img src={selfie} alt="selfie" /> */}
          {component} {text ?? ""}
        </span>
      </Button>
    </div>
  );
};

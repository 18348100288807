import React from "react";
import style from "./styles.module.scss";
import { formatDateTime, formatTime } from "../../utils/dateTime";
import { BsGeo } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { ModalDetails } from "../Modal";
import { If } from "../If";
import { switchCaseIcons } from "../../utils/switchCaseIcons";

interface CardHistoryProps {
  type?: string;
  icon?: React.ReactElement;
  list?: any;
}

export const CardRequest = ({ type, icon, list }: CardHistoryProps) => {
  const [open, setOpen] = React.useState(false);
  const [item] = React.useState(null);
  const handleClose = () => setOpen(false);
  return (
    <>
      {list.length > 0 &&
        list.map((item: any, index: number) => {
          return (
            <>
              <div className={style.card} key={index}>
                <div className={style.containerTitle}>
                  <div
                    className={style.details}
                    // onClick={() => handleOpen(list)}
                  >
                    <span>{item.status}</span>
                    <span>{switchCaseIcons(item.status)}</span>
                  </div>
                  <span>Data: {formatDateTime(item.old_schedule)}</span>
                </div>
                <div className={style.containerContent}>
                  <div className={style.content}>
                    <div className={style.address}>
                      <span>
                        <BsGeo
                          style={{
                            marginRight: ".5rem",
                            marginBottom: ".5rem",
                          }}
                        />
                      </span>
                      <p className={style.pointTitle}> Ponto Atual: </p>
                    </div>
                    <p>
                      {" "}
                      <span className={style.description}>
                        <BiTime style={{ marginRight: ".5rem" }} />{" "}
                        {item.point_register != null &&
                          item.point_register.type}{" "}
                        {"  "}
                        {formatTime(item.old_schedule)}
                      </span>
                    </p>
                    <div className={style.address}>
                      <BsGeo style={{ marginRight: ".5rem" }} />
                      <p className={style.pointTitle}> Ponto Alterado: </p>
                    </div>
                    <p className={style.description}>
                      {" "}
                      <BiTime style={{ marginRight: ".5rem" }} />{" "}
                      {item.schedule !== null
                        ? `${item.point_register.type} ${formatTime(
                            item.point_register.schedule
                          )}`
                        : "Aguardando Análise"}
                    </p>
                  </div>
                  <div className={style.obs}>
                    <p>{item.obs === null ? "" : `Observação: ${item.obs}`}</p>
                  </div>
                </div>
              </div>
            </>
          );
        })}

      <If condition={item != null}>
        <ModalDetails
          open={open}
          item={item}
          handleClose={handleClose}
          setOpen={setOpen}
        />
      </If>
    </>
  );
};

import { useCallback, useEffect, useRef, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { Banner } from "../../components/Banner";
import { IfLoaderInsideScreen } from "../../components/IfLoaderInsideScreen";
import { Layout } from "../../components/Layout";
import style from "./styles.module.scss";
import { useApproval } from "../../providers/Approval";
import { CardItemApproval } from "../../components/CardItemApproval";

export default function Approvals() {
  const { getApprovals } = useApproval();

  const [loading, setLoading] = useState(false);

  const [listApprovals, setListApprovals] = useState<any>([]);

  const firstLoad = useRef(true);

  const getApproval = useCallback(async () => {
    setLoading(true);
    const list = await getApprovals();
    setListApprovals(list);
    setLoading(false);
  }, [getApprovals]);

  const updateList = useCallback(async () => {
    const list = await getApprovals();
    setListApprovals(list);
  }, [getApprovals]);

  useEffect(() => {
    if (firstLoad.current === true) {
      getApproval();
    }
    return () => {
      firstLoad.current = false;
    };
  }, [firstLoad, getApproval, setListApprovals]);

  return (
    <Layout>
      <Banner text="Aprovações" icon={<FiCheck />} />
      <div className={style.container}>
        <IfLoaderInsideScreen loading={loading}>
          <CardItemApproval list={listApprovals} updateList={updateList} />
        </IfLoaderInsideScreen>
      </div>
    </Layout>
  );
}

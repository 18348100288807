import { useEffect, useState } from "react";
import { AiOutlineHistory } from "react-icons/ai";
import { Banner } from "../../components/Banner";
import { CardHistory } from "../../components/CardHistory";
import { IfLoaderInsideScreen } from "../../components/IfLoaderInsideScreen";
import { Layout } from "../../components/Layout";
import { useAuth } from "../../providers/Auth/Auth";
import { useHistory } from "../../providers/History";
import style from "./styles.module.scss";
export default function History() {
  const { getHistory, history } = useHistory();
  const [loading, setLoading] = useState(true);

  const { userData } = useAuth();

  useEffect(() => {
    getHistory(setLoading);
  }, [getHistory, userData]);

  return (
    <Layout>
      <IfLoaderInsideScreen loading={loading}></IfLoaderInsideScreen>
      <Banner
        icon={<AiOutlineHistory className="history" size={25} />}
        text="Histórico"
      />
      <>
        {history.length > 0 && (
          <div className={style.container}>
            {history.map((item: any) => {
              return <CardHistory key={item.id} list={item} />;
            })}
          </div>
        )}
      </>
      {/* <IfLoading loading={loading}>
        <div className={style.container}>
          {history.map((item: any) => {
            return <CardHistory key={item.id} list={item} />;
          })}
        </div>
      </IfLoading> */}
    </Layout>
  );
}

import { Box } from "@mui/material";
import React from "react";
import style from "./style.module.scss";

export const Banner = ({ text, icon }: any) => {
  return (
    <Box>
      <div className={style.header}>
        <h2> {icon}  {text} </h2>
      </div>
    </Box>
  );
};

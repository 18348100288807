import { createContext, useCallback, useContext, useState } from "react";
import { customToast } from "../../utils/toast";
import { useAuth } from "../Auth/Auth";
import { user } from "../Auth/services";

const HistoryContext = createContext<any>(null);

export const HistoryProvider = ({ children }: any) => {
  const [shift, setShift] = useState([]);
  const [loader, setLoading] = useState(true);
  const { userData } = useAuth();
  const [history, setHistory] = useState([]);

  const getShift = (user_id: any, set: any) => {
    setLoading(true);
    user
      .getShifts(user_id)
      .then((res: any) => {
        set(res.data.response);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getHistory = useCallback(
    async (setLoader: any) => {
      setLoader(true);
      if (userData == null) {
        return console.log("User Data nulo");
      }
      await user
        .history(userData)
        .then((response) => {
          setHistory(response.data.response.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          customToast("Erro ao carregar histórico", "error");
        });
    },
    [userData]
  );

  return (
    <HistoryContext.Provider
      value={{ shift, setShift, getShift, loader, history, getHistory }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);

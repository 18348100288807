import { FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "../../styles/Login.module.scss";
import logo from "../../assets/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
import { usePassword } from "../../providers/Password";
import { customToast } from "../../utils/toast";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { newPasswordInLogin } = usePassword();

  const [password, setPassword] = useState("");
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealPasswordConfirm, setRevealPasswordConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [passwordConfirm, setPasswordConfirm] = useState("");

  const handleResetPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);

    if (password !== passwordConfirm) {
      setLoading(false);
      return customToast("As senhas não conferem", "error");
    }

    await newPasswordInLogin(password);
    navigate("/login");
    setLoading(false);
  };

  function RevealPassword(event: any) {
    event.preventDefault();
    setRevealPassword(!revealPassword);
  }
  function RevealPasswordConfirm(event: any) {
    event.preventDefault();
    setRevealPasswordConfirm(!revealPasswordConfirm);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={logo} alt="logo-login" />
          <form onSubmit={handleResetPassword}>
            <div className={styles.title}>
              <h2>Redefinir Senha</h2>
            </div>
            <Input
              name="password"
              icon={FiLock}
              type={revealPassword ? "text" : "password"}
              placeholder="Insira sua senha"
              iconEye={revealPassword ? FiEyeOff : FiEye}
              showPassword={RevealPassword}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              name="confirmPassword"
              icon={FiLock}
              type={revealPasswordConfirm ? "text" : "password"}
              placeholder="Confirm sua nova senha"
              iconEye={revealPasswordConfirm ? FiEyeOff : FiEye}
              showPassword={RevealPasswordConfirm}
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />

            <Button type="submit" disabled={loading}>
              {loading ? <> {<Spinner size="sm" />} </> : "Login"}{" "}
            </Button>
          </form>
        </div>

        <div className={styles.background}></div>
      </div>
    </>
  );
}

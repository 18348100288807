import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customToast } from "../../utils/toast";
import { passwordChangeLogin, passwordService } from "./service";

const PasswordContext = createContext<any>(null);

export const PasswordProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string>("");

  const changePassword = async (password: any, newPassword: any) => {
    setLoading(true);
    try {
      const response = await passwordService.putPassword(password, newPassword);
      customToast("Sua Senha foi alterada com Sucesso!", "success");
      setLoading(false);
      return response;
    } catch (err: any) {
      customToast(err.response.data.message, "error");
      setLoading(false);
    }
  };

  const sendEmailLogin = async (email: any, setOpenModalCode: any) => {
    try {
      const response = await passwordChangeLogin.sendEmail(email);
      customToast("Seu código foi enviado para o email cadastrado!", "success");
      setToken(response.data.response);
      setOpenModalCode(true);
    } catch (err: any) {
      customToast(err.response.data.message, "error");
    }
  };

  const verifyCode = async (code: any, handleClose: any) => {
    try {
      const response = await passwordChangeLogin.postCode(code, token);
      console.log(response.data)
      customToast("Código verificado com Sucesso!", "success");
      navigate("/forgotpassword/" + token);
      handleClose();
    } catch (err: any) {
      customToast(err.response.data.message, "error");
    }
  };

  const newPasswordInLogin = async (password: string) => {
    try {
      const response = await passwordChangeLogin.resetPassword(password, token);
      if (response === undefined) {
        return customToast("Suas credenciais estão inválidas!", "error");
      }
      customToast("Sua Senha foi alterada com Sucesso!", "success");
      navigate("/");
      setToken("");
    } catch (err: any) {
      customToast("Suas credenciais estão inválidas!", "error");
      navigate("/");
    }
  };

  return (
    <PasswordContext.Provider
      value={{ changePassword, loading, sendEmailLogin, verifyCode, newPasswordInLogin }}
    >
      {children}
    </PasswordContext.Provider>
  );
};

export const usePassword = () => {
  return useContext(PasswordContext);
};

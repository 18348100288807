import style from "./styles.module.scss";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useState } from "react";
import Input from "../Input";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { usePassword } from "../../providers/Password";
import { customToast } from "../../utils/toast";

export const ModalChangePassword = ({ open, handleClose, setOpen }: any) => {
  const { loading, changePassword } = usePassword();
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealPasswordConfirm, setRevealPasswordConfirm] = useState(false);
  const [revealPasswordOld, setRevealPasswordOld] = useState(false);

  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [passwordOld, setPasswordOld] = useState<string>("");
  function RevealPassword(event: any) {
    event.preventDefault();
    setRevealPassword(!revealPassword);
  }
  function RevealPasswordConfirm(event: any) {
    event.preventDefault();
    setRevealPasswordConfirm(!revealPasswordConfirm);
  }
  function RevealPasswordOld(event: any) {
    event.preventDefault();
    setRevealPasswordOld(!revealPasswordOld);
  }

  const handleChangePassword = useCallback(async (e: any) => {
    e.preventDefault();
    if (passwordOld.length === 0)
      return customToast("Informe a senha atual", "error");
    if (password.length < 6)
      return customToast("A nova senha deve ter mais de 5 caracteres", "error");

    if (password !== passwordConfirm)
      return customToast("As senhas não conferem", "error");
    await changePassword(passwordOld, password);
    setOpen(false);
  }, [changePassword, password, passwordConfirm, passwordOld, setOpen]);

  return (
    <>
      <div className={style.container}>
        <Modal isOpen={open} toggle={handleClose} centered>
          <ModalHeader toggle={handleClose}>Alterar Senha</ModalHeader>
          <form onSubmit={handleChangePassword}>
            <ModalBody>
              <div className={style.formGroup}>
                <label htmlFor="oldPassword">Senha Atual</label>
                <Input
                  name="oldPassword"
                  placeholder="Insira sua senha atual"
                  type={revealPasswordOld ? "text" : "password"}
                  iconEye={revealPasswordOld ? FiEyeOff : FiEye}
                  showPassword={RevealPasswordOld}
                  value={passwordOld}
                  onChange={(e) => setPasswordOld(e.target.value)}
                />
              </div>
              <div className={style.formGroup}>
                <label htmlFor="newPassword">Nova Senha</label>
                <Input
                  name="newPassword"
                  type={revealPassword ? "text" : "password"}
                  placeholder="Insira sua senha nova"
                  iconEye={revealPassword ? FiEyeOff : FiEye}
                  showPassword={RevealPassword}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className={style.formGroup}>
                <label htmlFor="confirmPassword">Confirmar Nova Senha</label>
                <Input
                  name="newPassword"
                  placeholder="Confirme sua senha nova"
                  type={revealPasswordConfirm ? "text" : "password"}
                  iconEye={revealPasswordConfirm ? FiEyeOff : FiEye}
                  showPassword={RevealPasswordConfirm}
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outlined"
                className={style.btn}
                onClick={handleChangePassword}
                type="submit"
              >
                Alterar {loading && <Spinner size="sm" />}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </>
  );
};

import { CircularProgress } from "@mui/material";
import React from "react";
import style from "./styles.module.scss";
export const IfLoaderInsideScreen = ({ children, loading }: any) => {
  return loading ? (
    <div className={style.container}>
      <CircularProgress />
    </div>
  ) : (
    <>{children}</>
  );
};
